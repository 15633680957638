import React, { Component } from 'react';
import '../assets/css/header.css';
import navData from '../assets/data/nav.json';

class Nav extends Component {
  state = {
    scrolled: false,
    logo: true,
    isVisible: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    const params = new URLSearchParams(window.location.search);
    const scrollTo = params.get('scrollTo');

    if (scrollTo) {
      setTimeout(() => {
        this.scrollToSection(scrollTo);
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      this.setState({ isVisible: true });
      if (window.innerWidth > 991) {
        this.setState({ logo: false });
      } else {
        this.setState({ logo: true });
      }
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false, logo: true, isVisible: false });
    }
  };

  toggleSidebar = () => {
    const header = document.querySelector('.header');
    header.classList.toggle('active');
  };

  scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    // Supprimer le paramètre `scrollTo` de l'URL sans recharger la page
    const url = new URL(window.location);
    url.searchParams.delete('scrollTo');
    window.history.replaceState({}, '', url);
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const { logo, scrolled } = this.state;
    const logoSrc = scrolled ? navData.logo.scrolled : navData.logo.default;

    return (
      <>
        <div className="header" id="header">
          <nav className="navbar navbar-expand-lg">

            {/* Button */}

            <button
              className="navbar-toggler"
              type="button"
              onClick={this.toggleSidebar}
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Nav */}

            <div id="navbarSupportedContent">
              <ul className={`navbar-nav ${scrolled ? 'navbar-nav-scrolled' : ''}`}>
                <li className="nav-item">
                  <button className={`nav-link ${scrolled ? 'nav-link-scrolled' : ''}`} onClick={() => this.scrollToSection('social')}>
                    <img src={logoSrc} alt="Logo" className={`nav-logo ${scrolled ? 'logo-white' : 'logo-black'}`} draggable="false" />
                  </button>
                </li>
                {navData.menuItems.map((item) => (
                  <li key={item.id} className="nav-item">
                    <button
                      className={`nav-link ${scrolled ? 'nav-link-scrolled' : ''}`}
                      onClick={() => this.scrollToSection(item.id)}
                    >
                      {item.label}
                    </button>
                    <hr/>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        </div>

        {/* Chevron */}

        {this.state.isVisible && (
          <button className="scroll-top" onClick={this.scrollToTop}>
            <i className="fa-solid fa-chevron-up"></i>
          </button>
        )}
      </>
    );
  }
}

export default Nav;
