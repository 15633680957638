import React, { Component } from 'react';
import '../assets/css/about.css';

class About extends Component {
  render() {
    return (
      <>
        <div className='about' id="about">
          <h1>À Propos</h1>
        </div>
      </>
    )
  }
}

export default About;