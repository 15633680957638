import React, { Component } from 'react';
import '../assets/css/contact.css';
import contactInfo from '../assets/data/contactInfo.json';

class Contact extends Component {
  render() {
    return (
      <>
        <div className='contact' id="contact">
          <div className='head'>
            <h1>Contact</h1>
            <h4>Contactez-moi pour plus d'informations</h4>
            <hr/>
          </div>

          <div className='contact-head'>
            <div className='sec first'>
              {contactInfo.map((item, index) => (
                <div key={index}>
                  <div className='inline'>
                    <i className={item.icon}></i>
                    <h5>{item.title}</h5>
                  </div>
                  {item.details.map((detail, idx) => (
                    <p key={idx}>{detail}</p>
                  ))}
                  {index < contactInfo.length - 1 && <hr />}
                </div>
              ))}
            </div>

            <div className='sec second'>
              <iframe
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
                src="https://maps.google.com/maps?width=300&amp;height=600&amp;hl=en&amp;q=86%20Boulevard%20Eug%C3%A8ne%20R%C3%A9guillon+(Jordan%20Web%20Studio)&amp;t=&amp;z=6&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              >
                <a href="https://www.gps.ie/">gps tracker sport</a>
              </iframe>
            </div>

            <div className='sec third'>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
