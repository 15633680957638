import React, { Component, createRef } from 'react';
import '../assets/css/rate.css';
import rates from '../assets/data/rate.json';

class Rate extends Component {
  constructor(props) {
    super(props);
    this.skillsRef = createRef();
  }

  componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.skillsRef.current.classList.add('visible');
          } else {
            this.skillsRef.current.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (this.skillsRef.current) {
      observer.observe(this.skillsRef.current);
    }
  }

  render() {
    return (
      <>
        <div className='rate' id="rate" ref={this.skillsRef}>
          <div className='head'>
            <h1>Tarifs</h1>
            <h4>Des solutions tarifaires flexibles pour tous vos projets</h4>
            <hr></hr>
          </div>

          {/* Read rate.json */}

          {rates.map((rate, index) => (
            rate.display && (
              <div key={index} className={`rate-cat ${rate.available ? '' : 'unavailable'} ${rate.name}`}>
                <div>
                  <h5><b>{rate.name}</b><br/>{rate.description}</h5>
                  <hr />
                  <small>À partir de</small><br/>
                  {rate.reduce !== '' ? (
                    <>
                      <span className='price'>{rate.reduce}</span>
                      <span className='no-price'>{rate.price}</span>
                    </>
                  ) : (
                    <>
                      <span className='price'>{rate.price}</span>
                      <span className='info'>{rate.info ? rate.info : ''}</span>
                    </>
                  )}
                  <hr />
                </div>
                <ul>
                  {rate.features.map((feature, idx) => (
                    <li key={idx}><i className="fa-duotone fa-solid fa-plus"></i> {feature}</li>
                  ))}
                </ul>
                {!rate.available && (
                  <small><i className="fa-solid fa-circle-info"></i> Indisponible</small>
                )}
              </div>
            )
          ))}
        </div>
      </>
    )
  }
}

export default Rate;
